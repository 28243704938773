<template>
    <div>
      <limits-main></limits-main>
    </div>
  </template>
  
  <script>
  import LimitsMain from "@/components/ecologist/limits/limitsMain";
  export default {
    name: "LimitsMainView",
    components: { LimitsMain },
  };
  </script>
  
  <style scoped></style>
  